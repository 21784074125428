<template>
  <div class="dialog-box">
    <el-dialog
      :title="title"
      :visible.sync="showDialog"
      :width="width"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :top="top"
    >
      <div><slot></slot></div>
      <span slot="footer" v-if="title != '同步OA组织结构'">
        <el-button type="primary" @click="handleReqData">{{
          showTowText ? '确 定' : '确定添加'
        }}</el-button>
        <el-button @click="handleClose">{{
          showTowText ? '取 消' : '取消添加'
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ['title', 'showTowText', 'width', 'top', 'showDialog'],
  data() {
    return {}
  },
  mounted() {},
  methods: {
    handleClose() {
      this.$emit('handleClose')
    },
    handleReqData() {
      this.$emit('handleReqData')
    },
  },
}
</script>

<style>
.el-dialog__header {
  background-color: #4274c0;
  padding: 10px !important;
}
.el-dialog__title {
  font-size: 14px !important;
  color: white !important;
}
.el-dialog__headerbtn {
  top: 15px !important;
}
.el-dialog__headerbtn .el-dialog__close {
  color: white !important;
}
.el-dialog__footer {
  text-align: center !important;
}
.dialog-box .el-button {
  margin: 0 20px;
}
.el-dialog .el-input__inner {
  background-color: #eeeeee !important;
  border-radius: 0 !important;
}
</style>
